<template>
  <div class="inner-page">
    <div class="wrapper">
      <div class="reset-pass">
        <div class="hentry">
          <h2>{{ $t("forgotPassword.TitleResetPassword") }}</h2>
          <div class="txt">
            <p v-html="$t('forgotPassword.SubtitleResetPassword')"></p>
          </div>
        </div>
        <div class="form-basic">
          <form @submit.prevent="submitForm">
            <div class="fgroup">
              <label>New Password</label>
              <div class="pass-field">
                <input
                  :type="[show ? 'text' : 'password']"
                  v-model="password"
                  name="password"
                  required="required"
                />
                <b @click="show = !show" :class="{ merem: show }"></b>
              </div>
              <!-- end of pass field -->
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <div class="checky" style="text-align: center; color: red">
                <p v-for="(error, index) in errorMessage" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <button type="submit" class="button">
                Reset Password
              </button>
              <button
                ref="successBtn"
                type="button"
                data-src="#popresetpass"
                data-fancybox
                style="visibility: hidden"
              >
                Success
              </button>
            </div>
            <!-- end of group -->
          </form>
        </div>
        <!-- end of form basic -->
      </div>
      <div id="popresetpass" style="display: none;">
        <img src="/img/icon/check-reset.svg" />
        <p>Password changed successfully</p>
        <a href="/home#login" class="button">
          Done
        </a>
      </div>
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of inner page -->
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      show: false,
      password: null,
      errorMessage: []
    };
  },
  methods: {
    submitForm() {
      this.errorMessage = [];
      this.$axios
        .post(`/auth/reset-password`, {
          newPassword: this.password,
          validOtpToken: this.$route.params.token
        })
        .then(response => {
          console.log(response.data);
          this.$refs.successBtn.click();
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.errorMessage = error.response.data.data.map(
              error => error.message
            );
          } else if (error.response.data.code == 400) {
            this.errorMessage.push(error.response.data.message);
          }
        });
    }
  }
};
</script>

<style scoped>
.reset-pass {
  max-width: 480px !important;
}
</style>
